import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

export default function ApiClient (token?: string): ApolloClient<NormalizedCacheObject> {
  const authLink = setContext((_, { headers }) => {
    const newHeaders: { [key: string] : any } = {
      "x-hasura-admin-secret": process.env.NEXT_ADMIN_SECRET, 
    }
    
    return {
      headers: {
        ...headers,
        ...newHeaders
      }
    }
  });

  const httpLink = new HttpLink({
    credentials: 'include',
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    headers: {
      "x-hasura-admin-secret": process.env.NEXT_ADMIN_SECRET, 
    }
  })

  return client
}