import * as Sentry from '@sentry/react'
import format from 'date-fns/format'
import { BasketItems } from '../providers/basket'

export function deposit(price: number) {
  if (price > 100000) return 20000
  if (price > 20000) return 5000
  return 2500
}

export async function createIntent(
  amount: number,
  customerId?: string | null | undefined,
  email?: string | null | undefined
): Promise<{
  id: string
  client_secret: string
  amount: number
} | null> {
  if (!amount) return null

  const res = await fetch('https://charmelle.london/api/intent/?source=client', {
    mode: 'cors',
    method: 'post',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ amount, customerId, email }),
  })

  const json = await res.json()

  return json
}

export async function updateIntent(
  intent: string,
  amount: number,
  customerId?: string | null | undefined,
  email?: string | null | undefined,
  data?: any
): Promise<{
  id: string
  client_secret: string
  amount: number
} | null> {
  if (!amount) return null

  const res = await fetch('https://charmelle.london/api/intent/', {
    mode: 'cors',
    method: 'put',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ intent, amount, customerId, email, metadata: data }),
  })

  const json = await res.json()

  return json
}

export const toggleKlarna = () => {
  const elem = document.getElementById('klarna-frame')
  if (elem) {
    if (elem.classList.contains('hidden')) {
      elem.classList.replace('hidden', 'flex')
    } else {
      elem.classList.replace('flex', 'hidden')
    }
  }
}

export function setupKlarnaBinding() {
  setTimeout(() => {
    // @ts-ignore
    window._klarnaCheckout &&
      // @ts-ignore
      window._klarnaCheckout(function (api: any) {
        api.on({
          customer: (data: any) => {
            if (data) {
              // @ts-ignore
              window.heap && window.heap.addUserProperties(data)
              try {
                // @ts-ignore
                window.heap &&
                  // @ts-ignore
                  window.heap.track('customer', {
                    ...data,
                  })
              } catch (err) {
                Sentry.captureException(err)
                console.log(err)
              }
            }
          },
          change: function (data: any) {
            if (data.email) {
              // @ts-ignore
              window.heap && window.heap.addUserProperties(data)
              try {
                // @ts-ignore
                window.heap &&
                  // @ts-ignore
                  window.heap.track('change', {
                    ...data,
                  })
              } catch (err) {
                Sentry.captureException(err)
                console.log(err)
              }
            }
          },
          billing_address_change: function (data: any) {
            if (data) {
              // @ts-ignore
              window.heap && window.heap.addUserProperties(data)
              try {
                // @ts-ignore
                window.heap &&
                  // @ts-ignore
                  window.heap.track('billing_address_change', {
                    ...data,
                  })
              } catch (err) {
                Sentry.captureException(err)
                console.log(err)
              }
            }
          },
          can_not_complete_order: function (data: any) {
            if (data) {
              // @ts-ignore
              window.heap && window.heap.addUserProperties(data)
              try {
                // @ts-ignore
                window.heap &&
                  // @ts-ignore
                  window.heap.track('can_not_complete_order', {
                    ...data,
                  })
              } catch (err) {
                Sentry.captureException(err)
                console.log(err)
              }
            }
          },
        })
      })
  }, 1000)
}

export function evalScripts() {
  const container = document.getElementById('inner-klarna-frame')
  if (container) {
    var scriptsTags = container.getElementsByTagName('script')
    for (var i = 0; i < scriptsTags.length; i++) {
      var parentNode = scriptsTags[i].parentNode
      var newScriptTag = document.createElement('script')
      newScriptTag.type = 'text/javascript'
      newScriptTag.text = scriptsTags[i].text
      parentNode && parentNode.removeChild(scriptsTags[i])
      parentNode && parentNode.appendChild(newScriptTag)
    }
    setupKlarnaBinding()
  }
}

export async function createKlarna(
  state: any,
  items: BasketItems,
  price: number,
  duration: any
) {
  // @ts-ignore
  window._klarnaCheckout &&
    // @ts-ignore
    window._klarnaCheckout(function (api: any) {
      api.suspend()
    })

  const day = state.selectedDay ? format(state.selectedDay, 'yyyy-MM-dd') : null
  const time = state.selectedTime ? format(state.selectedTime, 'HH:mm') : null

  try {
    const res = await fetch('https://charmelle.london/api/pay-later/', {
      mode: 'cors',
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        details: state.details,
        // @ts-ignore
        selectedDay: day,
        // @ts-ignore
        selectedTime: time,
        member: state.member ? state.member.user.id : null,
        organization: process.env.NEXT_PUBLIC_ORGANIZATION_ID,
        duration,
        items,
        price,
      }),
    })

    const json = await res.json()

    setTimeout(() => {
      // @ts-ignore
      window._klarnaCheckout &&
        // @ts-ignore
        window._klarnaCheckout(function (api: any) {
          api.resume()
        })
    }, 1000)
    // console.log(json)
    return json
  } catch (err) {
    Sentry.captureException(err)
    console.log('Klarna error', err)
  }
}

export async function updateKlarna(
  state: any,
  items: BasketItems,
  price: number,
  klarna: any,
  duration: any
) {
  // @ts-ignore
  window._klarnaCheckout &&
    // @ts-ignore
    window._klarnaCheckout(function (api: any) {
      api.suspend()
    })

  const day = state.selectedDay ? format(state.selectedDay, 'yyyy-MM-dd') : null
  const time = state.selectedTime ? format(state.selectedTime, 'HH:mm') : null

  // console.log(state.selectedTime, time)
  try {
    const res = await fetch('https://charmelle.london/api/pay-later/', {
      mode: 'cors',
      method: 'put',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        details: state.details,
        // @ts-ignore
        selectedDay: day,
        // @ts-ignore
        selectedTime: time,
        member: state.member ? state.member.user.id : null,
        organization: process.env.NEXT_PUBLIC_ORGANIZATION_ID,
        duration,
        items,
        price,
        klarna,
      }),
    })

    const json = await res.json()
    // console.log(json)

    setTimeout(() => {
      // @ts-ignore
      window._klarnaCheckout &&
        // @ts-ignore
        window._klarnaCheckout(function (api: any) {
          api.resume()
        })
    }, 1000)
    return json
  } catch (err) {
    Sentry.captureException(err)
    console.log('Klarna error', err)
  }
}
