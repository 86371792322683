import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import * as Sentry from '@sentry/browser'
import { ApolloProvider } from '@apollo/client'
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
} from '@apollo/client'
import { getProfile } from '../queries/user'
import { UserProfile } from '../interfaces'
import setHours from 'date-fns/setHours'
import ApiClient from '../utils/client'

export interface User extends CognitoUser {
  attributes: {
    [key: string]: any
  }
}

interface State {
  loading: boolean
  user?: User
  type?: string
  claims?: { [key: string]: string }
  token?: string
  profile?: UserProfile
  sourceParams?: { [key: string]: string } | null
}

interface SessionContextValue extends State {
  logout: () => Promise<void>
  refresh: () => Promise<void | {
    user: User;
    claims: { [key: string]: string }
    type: any;
    token: string;
  }>
  setContext: React.Dispatch<React.SetStateAction<State>>
  loadUserProfile: () => Promise<void>
}

const initialState = {
  loading: true,
  user: undefined,
  type: undefined,
  token: undefined,
  claims: undefined,
  sourceParams: null
}

export const SessionContext = createContext(initialState as SessionContextValue)

export interface API {
  children: any
}

export const Session = ({ children }: API) => {
  const [state, setState] = useState<State>(initialState)
  const currentUser = () => {
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((data) => {
        console.log(data)
        const { idToken } = data.signInUserSession
        const type = idToken.payload['cognito:groups'][0]
        const claims =
          idToken.payload && idToken.payload['https://hasura.io/jwt/claims']
            ? JSON.parse(idToken.payload['https://hasura.io/jwt/claims'])
            : {}
        const token = idToken.jwtToken
        const contactNumber =
          claims && claims['x-user-contact-number']
            ? claims['x-user-contact-number']
            : '';

        if (data && data.attributes) {
          try {
            // @ts-ignore
            window.enhanced_conversion_data = {
              email: data.attributes.email,
              first_name: data.attributes.given_name,
              last_name: data.attributes.family_name,
              phone_number: contactNumber
            }

            Sentry.configureScope(function (scope) {
              const u = {
                id: data.attributes.sub,
                email: data.attributes.email,
                phone: contactNumber,
              }
              scope.setUser(u)
            });

            Sentry.setUser({
              id: data.attributes.sub,
              email: data.attributes.email,
              phone: contactNumber,
            });
          } catch (err) {
            Sentry.captureException(err)
            console.log(err)
          }

          // @ts-ignore
          klaviyo && klaviyo.push(['identify', {
            '$id': data.attributes.sub,
            '$email': data.attributes.email,
            '$first_name': data.attributes.given_name,
            '$last_name': data.attributes.family_name,
            '$phone_number': contactNumber
          }]);

          window.gtag && window.gtag('set', { user_id: data.attributes.sub });
          // @ts-ignore
          window.heap && window.heap.identify(data.attributes.sub);
          // @ts-ignore
          window.heap &&
            // @ts-ignore
            window.heap.addUserProperties({
              email: data.attributes.email,
              name: `${data.attributes.given_name} ${data.attributes.family_name}`,
            });
          // @ts-ignore
          window.Intercom &&
            // @ts-ignore
            window.Intercom('boot', {
              app_id: 'rn8g2h3e',
              email: data.attributes.email,
              name: `${data.attributes.given_name} ${data.attributes.family_name}`,
              user_id: data.attributes.sub,
              phone: contactNumber,
            });

          // @ts-ignore
          window.FS && window.FS.identify(data.attributes.sub, {
            email: data.attributes.email,
            displayName: `${data.attributes.given_name} ${data.attributes.family_name}`,
            user_id: data.attributes.sub,
            phone: contactNumber,
          });
        }

        const user = data as User;

        setState(s => ({
          ...s,
          user,
          claims,
          type,
          token,
        }))

        return {
          user,
          claims,
          type,
          token
        }
      })
      .catch((err) => {
        console.log(err)
        setState(s => ({ ...s, loading: false }))
      })
  }

  const asyncCurrentUser = () => {
    return Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((data) => {
        const { idToken } = data.signInUserSession
        const type = idToken.payload['cognito:groups'][0]
        const claims =
          idToken.payload && idToken.payload['https://hasura.io/jwt/claims']
            ? JSON.parse(idToken.payload['https://hasura.io/jwt/claims'])
            : {}
        const token = idToken.jwtToken
        const contactNumber =
          claims && claims['x-user-contact-number']
            ? claims['x-user-contact-number']
            : '';

        if (data && data.attributes) {
          try {
            // @ts-ignore
            window.enhanced_conversion_data = {
              email: data.attributes.email,
              first_name: data.attributes.given_name,
              last_name: data.attributes.family_name,
              phone_number: contactNumber
            }

            Sentry.configureScope(function (scope) {
              const u = {
                id: data.attributes.sub,
                email: data.attributes.email,
                phone: contactNumber,
              }
              scope.setUser(u)
            });

            Sentry.setUser({
              id: data.attributes.sub,
              email: data.attributes.email,
              phone: contactNumber,
            });
          } catch (err) {
            Sentry.captureException(err)
            console.log(err)
          }

          // @ts-ignore
          klaviyo && klaviyo.push(['identify', {
            '$id': data.attributes.sub,
            '$email': data.attributes.email,
            '$first_name': data.attributes.given_name,
            '$last_name': data.attributes.family_name,
            '$phone_number': contactNumber
          }]);

          window.gtag && window.gtag('set', { user_id: data.attributes.sub });
          // @ts-ignore
          window.heap && window.heap.identify(data.attributes.sub);
          // @ts-ignore
          window.heap &&
            // @ts-ignore
            window.heap.addUserProperties({
              email: data.attributes.email,
              name: `${data.attributes.given_name} ${data.attributes.family_name}`,
            });
          // @ts-ignore
          window.Intercom &&
            // @ts-ignore
            window.Intercom('boot', {
              app_id: 'rn8g2h3e',
              email: data.attributes.email,
              name: `${data.attributes.given_name} ${data.attributes.family_name}`,
              user_id: data.attributes.sub,
              phone: contactNumber,
            });

          // @ts-ignore
          window.FS && window.FS.identify(data.attributes.sub, {
            email: data.attributes.email,
            displayName: `${data.attributes.given_name} ${data.attributes.family_name}`,
            user_id: data.attributes.sub,
            phone: contactNumber,
          });
        }

        const user = data as User;

        setState(s => ({
          ...s,
          user: data as User,
          claims,
          type,
          token,
        }))

        return {
          user,
          claims,
          type,
          token
        }
      })
      .catch((err) => {
        console.log(err)
        setState(s => ({ ...s, loading: false }))
      })
  }

  useEffect(() => {
    currentUser()
    if (window && window.location.search && window.location.search.length > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlParams)
      try {
        localStorage.setItem('cl.p', JSON.stringify(params))
        setState(s => ({ ...s, sourceParams: params }))
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        const d = localStorage.getItem('cl.p')
        const e = JSON.parse(d)
        setState(s => ({ ...s, sourceParams: e }))
      } catch (err) {
        console.log(err)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadUserProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token])

  const loadUserProfile = useCallback(async () => {
    if (state.token && state.claims) {
      const client = ApiClient(state.token)
      try {
        const { data, error } = await client.query({
          query: getProfile,
          variables: {
            id: process.env.NEXT_PUBLIC_IMP_ID || state.claims['x-hasura-user-id'],
            date: setHours(new Date(), 0),
          },
          fetchPolicy: 'network-only'
        })

        if (error) {
          console.log('Error loading user profile', error)
        }

        setState((s) => ({
          ...s,
          loading: false,
          profile: data.user[0] as UserProfile,
        }))
      } catch (err) {
        Sentry.captureException(err)
        console.log('Error loading user profile', err)
      }
    }
  }, [state.token, state.claims])

  const logout = useCallback(async () => {
    try {
      await Auth.signOut()
      // @ts-ignore
      window.Intercom && window.Intercom('shutdown')
      setState(initialState)
      window.location.href = '/'
    } catch (err) {
      Sentry.captureException(err)
      console.log(err)
    }
  }, [])

  const value: SessionContextValue = {
    ...state,
    refresh: asyncCurrentUser,
    logout,
    setContext: setState,
    loadUserProfile,
  }

  const authMiddleware = new ApolloLink((operation, forward) => {
    // console.log(operation, operation.getContext().headers)
    if (process.env.NEXT_PUBLIC_IMPERSONATE && process.env.NEXT_PUBLIC_IMPERSONATE === 'true') {
      operation.setContext({
        headers: {
          'x-hasura-organization-id': process.env.NEXT_PUBLIC_ORGANIZATION_ID,
          "x-hasura-admin-secret": process.env.NEXT_PUBLIC_IMP_SECRET,
          "x-hasura-role-id": process.env.NEXT_PUBLIC_IMP_ROLE,
          "x-hasura-user-id": process.env.NEXT_PUBLIC_IMP_ID
        },
      })
    } else if (state.token) {
      operation.setContext({
        headers: {
          ...operation.getContext().headers,
          authorization: `Bearer ${state.token}`,
          'x-hasura-organization-id': process.env.NEXT_PUBLIC_ORGANIZATION_ID,
        },
      })
    } else {
      operation.setContext({
        headers: {
          ...operation.getContext().headers,
          'x-hasura-organization-id': process.env.NEXT_PUBLIC_ORGANIZATION_ID,
        },
      })
    }
    return forward(operation)
  })

  const httpLink = new HttpLink({
    credentials: 'include',
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  })

  const client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
  })

  return (
    <SessionContext.Provider value={value}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </SessionContext.Provider>
  )
}

export const SessionProvider = Session

export const useSession = () => useContext(SessionContext)
