import * as Sentry from '@sentry/react'
import {
  isBefore,
  addMinutes,
  isAfter,
  getMinutes,
  areIntervalsOverlapping,
  isWithinInterval,
  isEqual,
} from 'date-fns'

export default function generateRange(
  start: Date,
  end: Date,
  step: number,
  duration: number,
  blocks: {
    start: Date
    end: Date
  }[]
) {
  const arr = [start]

  let itr = start

  if (getMinutes(start) > 30) {
    itr = addMinutes(start, 60 - getMinutes(start))
    arr.push(itr)
  }

  let current = itr
  while (!isAfter(current, end) && !isEqual(current, end)) {
    const n = addMinutes(current, step)
    arr.push(n)
    current = n
  }

  const filtered = arr.filter((serviceStart) => {
    const serviceEnd = addMinutes(serviceStart, duration)
    if (isAfter(serviceEnd, end)) {
      return false
    }
    if (
      !isWithinInterval(serviceEnd, {
        start: serviceStart,
        end,
      })
    ) {
      return false
    }
    return true
    // const result = blocks
    //   .filter((block) => {
    //     if (isAfter(serviceEnd, end)) {
    //       return true
    //     }

    //     try {
    //       const serviceInterval = { start: serviceStart, end: serviceEnd }
    //       const blockInterval = { start: block.start, end: block.end }
    //       const overlapping = areIntervalsOverlapping(
    //         serviceInterval,
    //         blockInterval
    //       )
    //       if (overlapping) return true
    //     } catch (err) {
    //       console.log('serviceStart')
    //       console.log(serviceStart && serviceStart.toString())
    //       console.log('serviceEnd')
    //       console.log(serviceEnd && serviceEnd.toString())
    //       console.log('block.start')
    //       console.log(block.start && block.start.toString())
    //       console.log('block.end')
    //       console.log(block.end && block.end.toString())
    //       Sentry.captureException(err)
    //       console.log('areIntervalsOverlapping', err)
    //       console.log(block)
    //     }
    //     return false
    //   })
    //   .filter(Boolean)

    // return result.length === 0
  })

  return filtered
}
