import 'focus-visible'
import Script from 'next/script'
import '../styles/tailwind.css'
import Amplify from 'aws-amplify'
import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader'
import { SessionProvider } from '../providers/session'
import { BasketProvider } from '../providers/basket'

const config = {
  aws_project_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_cognito_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_COGNITO_ID_POOL,
  ssr: true,
}

Amplify.configure({
  ...config,
  ssr: true,
})

export default function App({ Component, pageProps }) {
  return (
    <>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-JW2DZNNF9X"
      ></Script>
      <Script>
        {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-JW2DZNNF9X');`}
      </Script>
      <Script strategy="afterInteractive" id="fb-sc">
        {`!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('consent', 'grant')
      fbq('init', '288312672007005');
      fbq('track', 'PageView');`}
      </Script>
      <Script
        strategy="lazyOnload"
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UUDnxX"
      />{' '}
      <Script strategy="afterInteractive" id="tt-sc">
        {`!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load('CCLH6UBC77UDI0MAGLF0'); ttq.page(); }(window, document, 'ttq');`}
      </Script>
      <Script strategy="afterInteractive" id="klay">
        {`
        !function() {
          if (!window.klaviyo) {
            window._klOnsite = window._klOnsite || [];
            try {
              window.klaviyo = new Proxy({}, {
                get: function(n, i) {
                  return "push" === i ? function() {
                    var n;
                    (n = window._klOnsite)
                    .push.apply(n, arguments)
                  } : function() {
                    for (var n = arguments.length, o = new Array(n), w = 0; w < n; w++) o[w] = arguments[w];
                    var t = "function" == typeof o[o.length - 1] ? o.pop() : void 0,
                      e = new Promise((function(n) {
                        window._klOnsite.push([i].concat(o, [function(i)
                          {
                            t && t(i), n(i)
                        }]))
                      }));
                    return e
                  }
                }
              })
            }
            catch (n) {
              window.klaviyo = window.klaviyo || [], window.klaviyo.push = function() {
                var n;
                (n = window._klOnsite)
                .push.apply(n, arguments)
              }
            }
          }
        }();
        `}
      </Script>
      <LiveChatLoaderProvider
        providerKey="rn8g2h3e"
        provider="intercom"
        idlePeriod={200}
      >
        <SessionProvider>
          <BasketProvider>
            <Component {...pageProps} />
          </BasketProvider>
        </SessionProvider>
        <Intercom />
      </LiveChatLoaderProvider>
    </>
  )
}
